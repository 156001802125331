.pagination {
   padding: 10px 5px;
}
.disabled_btn.btn {
   opacity: .5;
   pointer-events: none;
}
.pagination .btn {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   padding: 8px;
}

.gotoPageInput input {
   border-radius: 5px;
   border: 1px solid #ddd;
   height: 30px;
   width: 41px;
   margin-right: 8px;
   text-align: center;
}