@keyframes rotate {
   0% {
     transform: rotate(0deg);
   }
   100% {
     transform: rotate(360deg);
   }
 }
 
 .loader {
  font-size: 1.3rem;
   animation: rotate 1.5s linear infinite;
 }
 