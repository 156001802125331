.sidebar {
  min-width: 279px;
  border-right: 1px solid rgba(145, 158, 171, 0.24);
  min-height: 100vh;
  transition: 0.3s all;
  background: var(--color5);
  position: fixed;
  z-index: 10000;
  max-height: 100vh;
  overflow-x: auto;
  padding-bottom: 10px;
}
.accordion-button::after {
  filter: invert(1);
}
.accordion-button:not(.collapsed) {
  background: var(--color11);
}
.sidebar.minimize {
  max-width: 83px !important;
  min-width: 83px !important;
  width: 83px !important;
}
.sidebar_wrapper {
  position: relative;
}

.minimize .account_info > .profile_img + div {
  display: none;
}
.minimize .sidebar_wrapper .text,
.minimize .accordion-button::after {
  display: none;
}

.minimize .sidebar_header {
  margin: 10px 0;
}
.minimize .sidebar_header svg {
  width: 100%;
}
.sidebar.minimize .nav a {
  padding: 10px;
  text-align: center;
  display: block;
}

.sidebar nav {
  display: flex;
  flex-direction: column;
}

.minimize button.toggleSidebar {
  left: 65px;
}

button.toggleSidebar {
  position: fixed;
  left: 15px;
  z-index: 10000;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  padding: 0;
  top: 15px;
  background: var(--color5);
  border: 1px dashed var(--color11);
  transition: all 0.3s;
}

.sidebar_header {
  padding: 15px 10px;
  margin: 15px;
}

.sidebar .accordion-body a:before {
  content: "";
  display: block;
  height: 5px;
  width: 5px;
  background: #637381;
  border-radius: 100%;
  margin-right: 10px;
}

.sidebar_header > img {
  height: 30px;
}
.accordion {
  --bs-accordion-bg: transparent !important;
}
.sidebar .accordion-body {
  padding-top: 10px;
}

.sidebar .accordion-item {
  border-width: 0px;
}

.accordion-button::after {
  background-size: 13px;
  background-position: center;
}

.sidebar .noChild .accordion-button::after {
  display: none;
}

.sidebar .accordion-header button {
  padding: 0px 9px;
}

.sidebar .accordion-header button .nav-link img {
  /* opacity: 0.6; */
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.2)) saturate(4)
    hue-rotate(200deg) brightness(1.3);
  height: 23px;
}

.sidebar .nav {
  display: flex;
  flex-direction: column;
}

.sidebar .nav a {
  color: var(--color15);
  margin: 0px 0px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  width: 100%;
}

.accordion-button:not(.collapsed) {
  background: var(--color16);
}

.account_info {
  display: flex;
  align-items: center;
  padding: 10px;
  background: rgba(199, 157, 52, 0.1);
  border-radius: 10px;
  margin-top: 20px;
  transition: all 0.3s;
  cursor: pointer;
}
.account_info svg {
  height: 30px;
  width: 30px;
}
.sidebar.minimize .account_info {
  border-radius: 100px;
}
.sidebar.minimize .profile_img_sm {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.account_info p {
  color: var(--color1);
  font-weight: bold;
}

.account_info:hover {
  background: var(--color18);
}

.sidebar.minimize .accordion-collapse.collapse.show {
  display: none;
}

.content {
  padding-left: 83px;
}
@media screen and (max-width: 993px) {
}
@media only screen and (max-width: 1200px) {
  .sidebar {
    position: fixed;
    background: var(--color5);
    top: 0;
  }

  .content {
    flex-grow: 1;
    margin-top: 0px !important;
    padding-left: 0px;
  }

  .sidebar.minimize {
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
  }

  .sidebar.minimize .nav {
    display: none;
  }

  .minimize button.toggleSidebar {
    left: 15px;
  }

  /* .sidebar {display: block;} */
}
