:root {
  --color1: #c79d34;
  --color2: #e5d09c;
  --color3: #ab8420;
  --color4: #94721e;
  --color5: #ffffff;
  --color6: #919eab;
  --color7: #f1f3f4;
  --color8: #000000;
  --color9: #f4f6f8;
  --color10: #be0000;
  --color11: rgba(145, 158, 171, 0.2);
  --color12: ;
  --color13: #212b36;
  --color14: #637381;
  --color15: #919eab;
  --color16: rgba(211, 211, 211, 0.3);
  --color17: rgba(199, 157, 52, 0.05);
 --color18: rgba(199, 157, 52, 0.2);
  --alpha1: .1;
  --alpha2: .2;
  --alpha3: .3;
  --alpha4: .4;
  --alpha5: .5;
  --alpha6: .6;
  --alpha7: .7;
  --alpha8: .8;
  --alpha9: .9;
  --alpha10: 1;
}