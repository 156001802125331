/* CSS */
.loading-skeleton {
   display: inline-block;
   width: 100%;
   height: 20px; /* Adjust the height as needed */
   background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
   background-size: 200% 100%;
   animation: loading 1.2s infinite;
 }
 
 @keyframes loading {
   to {
     background-position: -200% 0;
   }
 }
 