.pageHeading {
   font-size: 1.5rem;
   margin: 0px;
   font-weight: 700;
   line-height: 1.5;
   display: flex;
}

.pageHeading_title {
   margin-bottom: 8px;
}

.breadcrumb {
   margin-bottom: 0px;
}

.breadcrumb-item {
   display: flex;
   align-items: center;
   font-weight: normal;
}

.breadcrumb-item+.breadcrumb-item {
   margin-left: -10px;
}

.breadcrumb-item a {
   color: var(--color13) !important;
   text-decoration: none;
   font-size: 0.875rem;
}

.breadcrumb-item .dot {
   width: 4px;
   height: 4px;
   border-radius: 50%;
   background-color: var(--color15);
   user-select: none;
   margin-left: 16px;
   margin-right: 16px;
   display: block;
}

.breadcrumb-item:first-child .dot {
   display: none;
}

.breadcrumb-item+.breadcrumb-item::before {
   content: '';
}

.breadcrumb-item:last-child:not(.breadcrumb-item:first-child) {
   /* display: none; */
}

.breadcrumb-item:last-child * {
   opacity: .7;
   pointer-events: none;
}

.breadcrumb-item:hover {
   text-decoration: underline;
}

@media screen and (max-width:768px) {
   
   .pageHeading_title {
      margin-bottom: 0px;
      font-size: 1.1rem;
   }

   .pageHeading {
      padding-left: 45px;
   }

   .breadcrumb {
      margin-bottom: 0px;
   }

   .right-sidebar .body ul {
      height: calc(100vh - 40px);
      margin-bottom: 10px;
      overflow: auto;
  }
}