.mapicon {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
  background: var(--color18);
}

.guest-checkout-errors {
  background-color: #f8d7da;
  padding: 1rem;
  border-radius: 0.25rem;
  margin-bottom: 20px;
  color: #a22029;
}
.guest-checkout-errors li {
  color: #a22029;
}
