.no-arrow.nav-item.dropdown .dropdown-toggle::after {
  display: none;
}

.navbar {
  background: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: saturate(180%) blur(10px);
  position: sticky;
  width: 100%;
  z-index: 0;
  top: 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.navbar .navbar-nav {
  display: flex;
  align-items: center;
}

.navbar .iconlink {
  font-size: 1.3rem;
}
.profile_img > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
header {
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 1200px) {
.navbar {
  margin-bottom: 5px;
}
}
@media screen and (max-width: 768px) {
  header {
    display: none;
  }
}
