/* Scrollbar Track */
*:hover ::-webkit-scrollbar {
   opacity: 1;
   border-radius: 3px;
}
*::-webkit-scrollbar {
   width: 3px; /* Set the width of the scrollbar track */
   height: 3px;
   opacity: 0;
   border-radius: 5px;
 }
 
 /* Scrollbar Thumb (the draggable part of the scrollbar) */
 *::-webkit-scrollbar-thumb {
   background-color: var(--color1); /* Set the color of the scrollbar thumb */
   border-radius: 3px; /* Round the corners of the thumb */
 }
 
 /* Scrollbar Track when hovering over it */
 *::-webkit-scrollbar-track:hover {
   background-color: var(--color18); /* Set the color when hovering over the track */

 }


 /* Scrollbar Width (thin or auto) */
*{scrollbar-width: thin;border-radius: 3px;}

/* Scrollbar Color */
*{scrollbar-color: var(--color1) var(--color18);} /* thumb color, track color */